import React from 'react'
import {Basic} from "../components/SEO";
import EbookForm from "../components/ebookForm";
import favicon from "../resources/favicon.png";
import Helmet from "react-helmet";
import {withPrefix} from "gatsby";

const pages = (props) => {
    return (
        <>
            <Helmet>
                <html lang="en"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta httpEquiv="Content-Language" content="en"/>
                <link rel="shortcut icon" type="image/png" href={favicon}/>
                <noscript>
                    {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5NJXQFW"
                            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
                </noscript>
                <script async={true} src={withPrefix("gtm.js")}></script>
            </Helmet>
            <Basic seo={{title: props.pageContext.book.name, description: props.pageContext.book.name}}/>
            <main>
                <EbookForm data={props.pageContext.book}/>
            </main>
        </>
    )
};

export default pages
